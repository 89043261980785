#myImageModal{
  z-index: 1051;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.embed {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 23px;
}
.showAllSwitch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

.input_inc{
    float: left;
    margin-top: 0px;
    margin-right: 2px;
}
.move-input{
    margin-right: 5px;
}
.move-bottons{
  position: fixed;
    right: 40px;
    top: 270px;
}
.move-bottons p{
  margin: 0;font-size: 25px;cursor: pointer;
}
.popup-arrow{
    height: 10px;
    width: 10px;
    position: absolute;
    background: rgb(255, 255, 255);
    transform: rotate(45deg);
    margin: -5px;
    z-index: -1;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 1px;
    top: 100%;
    left: 50%;
}
.popup-content{
    position: absolute;
    z-index: 2;
    width: auto;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(187, 187, 187);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px;
    padding: 5px;
    top: -80px;
    left: -24.8438px;
}
.popup-content1{
    position: absolute;
    z-index: 2;
    width: auto;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(187, 187, 187);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px;
    padding: 5px;
    top: 0px;
    left: 227.578px;
}
.popup-arrow1 {
    height: 10px;
    width: 10px;
    position: absolute;
    background: rgb(255, 255, 255);
    transform: rotate(135deg);
    margin: -5px;
    z-index: -1;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 1px;
    top: 19px;
    left: 0%;
  }
.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.embedslider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 23px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
}

.showAllSlide:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 23px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
input:checked + .showAllSlide:before {
  -webkit-transform: translateX(11px);
  -ms-transform: translateX(11px);
  transform: translateX(11px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider.round { 
  border-radius: 34px;
}
.slider.round:before { 
  border-radius: 50%;
}
.modal-body {
    position: relative;
    overflow-y: auto;
    max-height: 400px;
    padding: 15px;
}
/* .loader:empty {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
} */

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.reward-input{ width: auto; display: inline-block; margin-left: 9px;}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
